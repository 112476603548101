h1.entry-title {
  font-size: 2.25rem;
}

h2.entry-title {
  text-transform: uppercase;
}

.posts-navigation {
  margin: 2rem 0 3rem;
}

h4.big {
  color: $blueberry;
  font-size: 2rem;
}

.row.more-news {
  padding: 4rem 0 2rem;
}

.single-post .news-list h3.big {
  font-size: clamp(1.5rem, 4vw, 1.85rem);
}

.single-post .news-list .desc p {
  line-height: clamp(1.25rem, 4vw, 1.65rem);
  font-size: clamp(1.25rem, 4vw, 1.45rem);
}

.single-post table,
.single-post tr,
.single-post td {
  height: auto !important;
}

.posts-navigation .nav-links {
  .nav-previous {
    float: left;
  }

  .nav-next {
    float: right;
  }

  a {
    display: inline-flex;
    border: 2px solid $h1-header-color;
    border-radius: 4px;
    padding: 0.8rem 1rem;
    color: $purewhite;
    background-color: $h1-header-color;
    margin: 1rem 0 2rem;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;
    font-size: $h5-size;
    font-family: $header-font;
  }

  a:hover,
  a:active {
    color: $purewhite;
    background-color: $blueberry;
    text-decoration: none;
    border: 2px solid $blueberry;
  }
}

@include media-breakpoint-down(md) {
  .single-post table,
  .single-post tr,
  .single-post td {
    height: auto !important;
    width: auto !important;
  }
}
