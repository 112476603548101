footer.content-info {
  background-color: $blueberry;
  color: $purewhite;
  padding: 2rem 0;
  min-height: 30vh;

  p {
    color: $purewhite;
  }

  a {
    color: $purewhite;
  }
}

footer .brand {
  background-image: url("/images/FDSA_logo_pysty_nega.png");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  width: 100%;
  height: 191px;
  margin-top: 0.5rem;

  span {
    display: none;
  }
}

footer ul.nav {
  list-style: none;
  margin: 0;
  padding: 0;
}

footer .nav li {
  width: 100%;
}

footer .nav a {
  color: $purewhite;
  padding: 0 1rem;
  font-size: $h3-max-size;
  line-height: 2.125rem;
  font-family: $h1-header-font;
}

footer .nav ul.children {
  display: none;
}

footer .someikonit {
  margin-bottom: 4rem;
  text-align: right;

  img {
    margin-left: 0.2rem;
  }
}
