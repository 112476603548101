/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";

$theme-colors: (
  primary: #002ea2,
  secondary: #0b1560,
  light: #f1f4f7,
  dark: #000
);

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1650px
);

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 960px,
  xl: 1140px,
  xxl: 1370px,
  xxxl: 1620px
);

$finland: #002ea2;
$blueberry: #0b1560;
$snow: #f1f4f7;
$purewhite: #fff;
$leaf: #299310;
$summer: #fc5700;

$background-color: #fff;
$h1-header-color: #002ea2;
$h3-header-color: #000;
$h4-header-color: #464646;
$menu-link-color: #000;
$text-color: #666;
$text-color-reverse: #fff;
$hero-font-color: #fff;
$hero-font-color-reverse: #000;
$link-hover-color: #212121;
$banner-background: #f3f3f5;
$banner-background-safari: rgba(32, 42, 53, 0.6);
$banner-background-white: rgba(255, 255, 255, 0.8);
$banner-height: 54px;
$banner-height-fixed: 80px;
$h1-header-font: "finlandica";
$header-font: "finlandica";
$text-font: "Lato";
$font-stack: "Lato", "finlandica", "Helvetica Neue","Calibri Light", "Roboto",sans-serif;

$font-size: 16px; /* 16px */
$text-font-weight: 400;
$line-height: 1.5rem;

$h1-max-size: 4.5rem;
$h2-max-size: 1.75rem;
$h3-max-size: 1.5rem;
$h4-max-size: 1.25rem;
$nav-child-link-size: 1.125rem;
$article-h1-max-size: 5rem;
$superjumbo-max: 12.5rem;
$h4-size: 1rem;
$h5-size: 0.875rem;
$readmore-size: 0.75rem;
$jumbo: 4.5rem;
$nav-font-size: 0.875rem;
$h1-min-size: 2.5rem;
$h2-min-size: 1.35rem;
$h3-min-size: 1.2rem;
$article-h1-min-size: 3rem;
$superjumbo-min: 4.5rem;

$header-weight: 700;
$h1-weight: 700;
$h2-weight: 400;
$h3-weight: 400;
$h4-weight: 700;
$h5-weight: 700;
$p-weight: 400;
$p-light: 200;
$p-ext-light: 100;
$nav-link-weight: 700;
$hero-image-width: 100%;
$bodymargin: 15px;

/* Hamburgers */
$hamburger-active-layer-color: #fff;

/*
@media (min-width: 720px){
  $jumbo: 4.5rem;
  $nav-font-size: 3rem;
  $article-h1-size: 3.75rem;
}

@media (min-width: 991px){
  $h2-size: 1.5rem;
  $jumbo: 5.5rem;
  $nav-font-size: 3.75rem;
  $article-h1-size: 4.5rem;
}

@media (min-width: 1200px){
  $jumbo: 7.5rem;
  $nav-font-size: 4.5rem;
  $article-h1-size: 7.5rem;
}
*/
