.vrap {
  padding-top: 0.5rem;
}

.home h1 {
  margin-top: 2rem;
}

.home .mobileContent {
  margin: -3rem 0 2rem;
}

.heroContainer {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  h1,
  p {
    color: $purewhite;
  }

  p {
    font-size: $h2-max-size;
    font-family: $h1-header-font;
    margin-bottom: 2.5rem;
  }

  .subpage-listing-buttons {
    margin: 2rem 0;
  }

  .subpage-listing-buttons .readmore {
    margin: 0 1rem 1rem 0;
  }

  .link {
    margin-right: 0.5rem;
    margin-left: 5px;
  }
}

.page .heroContainer {
  // min-height: 72.5vh;
  height: auto;
  min-height: 38.5vw;
}

.membership-cards {
  h3 {
    color: rgba(0, 0, 0, 0.87);
  }
}

.vc_tta-title-text {
  color: $finland;
  font-family: $header-font;
}

.card {
  margin-top: 1rem;
  padding: 1rem 1.2rem;
  color: $finland;
  text-align: center;
  background-color: $snow;
  border-radius: 1rem;
  border: none;

  h3 {
    max-width: 90%;
    margin: 0 auto 0.5rem;
    color: $finland;
  }

  .price {
    font-size: clamp(1.5rem, 4vw, 4.5rem);
    margin: 0 auto 0.5rem;
  }

  .disclaimer {
    color: $text-color;
    margin-bottom: 1rem;
  }

  strong a {
    margin: 0.5rem 0 1rem;
  }
}

.bannercontainer {
  position: relative;

  .hero {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;

    .row {
      // position: absolute;
      // width: 100%;
      height: 100%;
      // left: 0;
      // right: 0;
    }

    .hero-contents {
      text-align: center;
      padding-top: 2rem;

      h5 {
        color: $purewhite;
      }
    }
  }
}

.memberslider {
  display: block;
  width: 100%;
  margin: 2rem 0;

  .allmemberslink {
    text-align: center;
    padding: 1rem 0 1rem;

    a {
      color: $text-color;
      text-decoration: underline;
    }

    a:hover,
    a:active {
      color: $finland;
    }
  }
}

.single-event {
  margin: 3rem 0;
  font-family: $header-font;

  p.announcement {
    font-size: $h3-max-size;
  }

  p.desc {
    font-family: $header-font;
    font-size: clamp(1rem, 4vw, 1.75rem);
    line-height: clamp(1.5rem, 4vw, 2.25rem);
    color: $finland;
  }

  h3 {
    margin: 1rem 0;
  }
}

.news-list,
.news-arcticles {
  .article-preview {
    margin-bottom: 2rem;
  }

  .announcement {
    font-size: clamp(1rem, 4vw, 1.25rem);
    font-family: $header-font;
  }

  h3.big {
    font-size: clamp(1.5rem, 4vw, 2rem); /* font-size: clamp(1.5rem, 4vw, 2.5rem); */
  }

  h3.strong {
    font-weight: bold;
    font-family: $header-font;
  }

  .desc p {
    color: $finland;
    font-family: $header-font;
    line-height: clamp(1.25rem, 4vw, 1.5rem); /* 1.75 */
    font-size: clamp(1.05rem, 4vw, 1.25rem); /* 1.5 */
  }

  .articelistimage {
    margin-top: 1rem;
  }
}

.page-content .wpb_text_column.ingress p:first-of-type,
.mobileContent .wpb_text_column.ingress p:first-of-type {
  margin: 2rem 0;
  font-family: $header-font;
  color: $finland;
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  line-height: clamp(1.75rem, 4vw, 2rem);
}

.mobileContent .wpb_text_column.ingress p:first-of-type {
  margin: 2rem 0;
  font-size: clamp(1.25rem, 4vw, 1.75rem);
}

.page .main ul {
  margin-bottom: 2rem;
}

.page .content {
  padding-bottom: 2rem;
}

.cat-list {
  margin-bottom: 3rem;
}

.wpcf7 form {
  padding: 1rem 1.2rem;
  color: #002ea2;
  background-color: #f1f4f7;
  border-radius: 1rem;
  border: none;

  label {
    width: auto;
    max-width: 100%;
    padding: 1rem;

    input {
      max-width: 100%;
    }
  }

  textarea {
    width: 100%;
  }

  h4 {
    margin: 2rem 0 1rem;
  }

  .wpcf7-list-item {
    margin: 0;
  }

  .wpcf7-list-item label {
    max-width: 100%;
  }

  .wpcf7-form-control-wrap {
    width: 100%;
    display: block;
  }

  input[type="submit"] {
    display: block;
    margin: 2rem 0;
    background: $finland;
    color: $purewhite;
    border: 2px solid $finland;
    border-radius: 4px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-family: $header-font;
    padding: 0.25rem 1rem;
  }

  input[type="submit"]:hover {
    background: $purewhite;
    color: $leaf;
    border-color: $leaf;
  }
}

.boardmember-image img.vc_single_image-img {
  border-radius: 33% !important;
  max-width: 176px;
}

.template-event {
  .fdcf-icons {
    padding: 1rem 0 0;
    border-right: 2px solid $finland;

    strong {
      color: $finland;
      text-transform: uppercase;
    }
  }

  h3 {
    color: $finland;
  }

  .row .fdcf-icons:last-of-type {
    border-right: none;
  }

  a.readmore.grey {
    margin-right: 1rem;
    margin-bottom: 0;
  }

  .extraMargins button,
  .vc_btn3-container.readmore.extraMargins {
    margin: 3rem auto !important;
  }

  .book-your-ticket {
    color: $purewhite;

    h2 {
      font-family: $h1-header-font;
      font-weight: $h1-weight;
      color: $purewhite;
      text-transform: uppercase;
      font-size: clamp(1.5rem, 4vw, 4.5rem);
      line-height: 1;
    }

    #clockdiv {
      text-align: center;
      font-family: $header-font;
      font-size: $h3-max-size;

      time {
        display: block;
        font-size: clamp(1.5rem, 4vw, 4.5rem);
      }
    }
  }

  .speakers .wpb_wrapper .wpb_text_column {
    text-align: center;
    max-width: 260px;
    margin-left: auto;
    margin-right: auto;
  }

  .speaker-image img.vc_single_image-img {
    border-radius: 33%;
    max-width: 176px;
  }

  .mainspeakers {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .mainspeakers .vc_col-sm-3 {
    min-width: 50%;
  }

  .mainspeakers .wpb_wrapper .wpb_text_column,
  .otherspeakers .wpb_wrapper .wpb_text_column {
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
  }

  .mainspeakers .speaker-image img.vc_single_image-img {
    border-radius: 33%;
    max-width: 100%;
  }

  .otherspeakers .speaker-image img.vc_single_image-img {
    border-radius: 33%;
    max-width: 100%;
  }
}

@include media-breakpoint-only(xs) {
  .template-event .otherspeakers .vc_col-sm-3 {
    max-width: 50%;
  }
}

@include media-breakpoint-up(sm) {
  .template-event .otherspeakers .vc_col-sm-3 {
    max-width: 25%;
  }
}

@include media-breakpoint-up(lg) {
  .template-event .otherspeakers .speaker-image img.vc_single_image-img {
    border-radius: 33%;
    max-width: 250px;
  }
}

@include media-breakpoint-up(xxxl) {
  .template-event .mainspeakers .vc_col-sm-3 {
    min-width: 25%;
  }
}

.bannercontainer .bg-image .img-fluid {
  width: 100vw;
}

.home .page-content .frontpagecontent {
  h2 {
    font-size: clamp(2.5rem, 5vw, 4.5rem);
    font-family: $h1-header-font;
    font-weight: $h1-weight;
    color: $h1-header-color;
    text-transform: uppercase;
  }

  .wpb_text_column.ingress p:first-of-type {
    margin-top: 0;
  }
}

.subpage-listing {
  .textblock {
    p.big {
      // font-size: $h2-max-size;
      font-size: clamp(1.125rem, 4vw, 1.5rem);
      color: $finland;
      line-height: 1.2;
    }
  }

  .subpage {
    margin-bottom: 1rem;
    padding-top: 1rem;
  }
}

.page-child h1:first-of-type {
  margin: 2rem 0;
}

.page-id-14 {
  .wpcf7 form {
    label {
      max-width: 100%;
      width: 100%;

      input {
        width: 100%;
      }
    }

    label.message {
      max-width: 100%;
      width: 100%;
    }

    textarea {
      width: 100%;
    }
  }
}

@include media-breakpoint-down(sm) {
  .main {
    margin-top: -4rem;
  }

  .heroContainer {
    padding-top: 2rem;
  }

  form > label {
    max-width: 100%;
    width: 100%;

    input {
      display: block;
      width: 100%;
    }
  }
}

@include media-breakpoint-only(md) {
  .membership-cards {
    .cards {
      padding-left: 1rem;
    }
  }

  .wpcf7 form {
    label {
      max-width: 48%;
    }
  }

  .page-id-14 {
    .wpcf7 form {
      label {
        max-width: 48%;
        width: 100%;

        input {
          width: 100%;
        }
      }

      label.message {
        max-width: 100%;
        width: 97%;
      }

      textarea {
        width: 100%;
      }
    }
  }

  .subpage-listing {
    .subpage {
      margin-bottom: 1rem;
      padding-top: 5rem;
    }
  }

  .page-content {
    h2,
    h3 {
      margin-top: 1rem;
    }

    h4,
    h5,
    h6 {
      margin-top: 0.75rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .membership-cards {
    .cards {
      padding-left: 3rem;
    }
  }

  .page .heroContainer {
    min-height: 72.5vh;
  }

  .page-content {
    h2,
    h3 {
      margin-top: 1rem;
    }

    h4,
    h5,
    h6 {
      margin-top: 0.75rem;
    }
  }

  .bannercontainer .hero .hero-contents h5 {
    margin: 4rem auto 4rem;
  }

  .wpcf7 form {
    label {
      max-width: 48%;
    }
  }

  .page-id-14 {
    .wpcf7 form {
      label {
        max-width: 48%;
        width: 100%;

        input {
          width: 100%;
        }
      }

      label.message {
        max-width: 100%;
        width: 97%;
      }

      textarea {
        width: 100%;
      }
    }
  }

  .subpage-listing {
    .subpage {
      margin-bottom: 2rem;
      padding-top: 5rem;
    }
  }
}

@include media-breakpoint-up(xl) {
  .bannercontainer .hero .hero-contents h5 {
    margin: 0 auto 20%;
  }

  .page-content .wpb_text_column.ingress p:last-of-type {
    margin: 0 0 5rem 0;
  }

  .home .page-content .wpb_text_column.ingress p:last-of-type {
    margin: 0 0 2rem 0;
  }

  .single-event {
    margin: 3rem auto 8rem auto;

    h3 {
      margin: 3rem 0;
    }
  }

  .wpcf7 form {
    label {
      max-width: 33%;
    }
  }

  .page-id-14 {
    .wpcf7 form {
      label {
        max-width: 48%;
      }

      label.message {
        max-width: 100%;
        width: 97%;
      }

      textarea {
        width: 100%;
      }
    }
  }

  .page-child .content {
    padding-bottom: 4rem;
  }

  .template-event {
    .extraMargins button {
      margin: 5rem auto !important;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .bannercontainer .hero .hero-contents h5 {
    margin: 0 auto 40%;
  }

  .card h3 {
    max-width: 100%;
  }

  .page-child .content {
    padding-bottom: 5rem;
  }

  .subpage-listing {
    .subpage {
      margin-bottom: 2rem;
    }
  }
}
