@font-face {
  font-family: "finlandica";
  src:
    url("/fonts/Finlandica-2018-08-15/WEB/finlandica-boldWeb.woff2") format("woff2"),
    url("/fonts/Finlandica-2018-08-15/WEB/finlandica-boldWeb.woff") format("woff"),
    url("/fonts/Finlandica-2018-08-15/WEB/finlandica-boldWeb.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "finlandica";
  src:
    url("/fonts/Finlandica-2018-08-15/WEB/finlandica-bolditalicWeb.woff2") format("woff2"),
    url("/fonts/Finlandica-2018-08-15/WEB/finlandica-bolditalicWeb.woff") format("woff"),
    url("/fonts/Finlandica-2018-08-15/WEB/finlandica-bolditalicWeb.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "finlandica";
  src:
    url("/fonts/Finlandica-2018-08-15/WEB/finlandica-italicWeb.woff2") format("woff2"),
    url("/fonts/Finlandica-2018-08-15/WEB/finlandica-italicWeb.woff") format("woff"),
    url("/fonts/Finlandica-2018-08-15/WEB/finlandica-italicWeb.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "finlandica";
  src:
    url("/fonts/Finlandica-2018-08-15/WEB/finlandica-regularWeb.woff2") format("woff2"),
    url("/fonts/Finlandica-2018-08-15/WEB/finlandica-regularWeb.woff") format("woff"),
    url("/fonts/Finlandica-2018-08-15/WEB/finlandica-regularWeb.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
