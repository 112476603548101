.banner {
  width: 100%;
  display: inline-block;
  // margin: 2.75rem 0 5rem;
  margin: 1.75rem 0 5rem;
  background-color: $purewhite;

  .navbar {
    padding: 0 1rem;
  }
}

.sticky-top {
  padding-top: 1rem;
}

.banner .nav-primary li {
  @extend .nav-item;
}

.banner .nav-primary li.btn {
  border: 1px solid $finland;
  filter: drop-shadow(0 0 0.25rem rgba(0, 46, 162, 0.2));
  background: #fff;
}

.banner .nav-primary a {
  @extend .nav-link;

  font-family: $header-font;
  font-size: $h5-size;
  font-weight: $nav-link-weight;
  color: theme-color("dark");
  text-transform: uppercase;
}

.banner .nav-primary a.dropdown-item {
  color: $finland;
}

.banner a.brand {
  background-image: url("/images/FDCA_logo_pysty_sininen.jpg");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 69px;
  // width: 56px;
  // height: 67px;
  // margin-left: 2rem;
  float: left;
  display: block;

  span {
    display: none;
  }
}

.home .banner a.brand {
  margin-left: 17px;
}

.template-event {
  .banner nav.nav-primary,
  .banner .navbar-toggler {
    display: none;
  }

  ul#menu-buy-tickets {
    margin-top: -1rem;
  }

  ul#menu-buy-tickets li a.nav-link {
    max-width: 200px;
  }
}

.mobileMenuVisible.banner {
  background-color: $finland;
  margin-top: 0;
  padding-top: 2.75rem;
  max-height: 100vh;
  overflow-y: auto;
  max-width: 100%;
  overflow-x: hidden;

  a.brand {
    background-image: url("/images/FDSA_logo_pysty_nega.png");
  }
}

.banner .nav-primary ul {
  list-style: none;
  margin: 0;
  padding: 0;
  float: left;

  li {
    float: left;
    padding: 0;
    margin: 0;
  }

  .dropdown-menu {
    border: none;
    border-radius: 0 0 1rem 1rem;
  }

  li.dropdown:hover {
    text-decoration: underline;
  }
}

.banner a.members-area {
  margin: 0;
  color: $purewhite;
  padding: 0.25rem 1rem;
}

.banner a.event {
  padding: 0.25rem 1rem;
  color: $blueberry;
}

.banner a.members-area:hover,
.banner a.members-area:active,
.banner a.event:hover,
.banner a.event:active {
  color: $purewhite;
}

.banner a.members-area.logged-in {
  margin-right: 0.4rem;
}

// .banner .nav ul.sub-menu {
//   display: none;
// }

// .banner .nav li a:hover ul.sub-menu {
//   display: block;
// }
@include media-breakpoint-down(md) {
  .navbar-toggler {
    background-color: $finland;
    padding: 0;
    width: 61px;
    height: 61px;
    float: right;

    .navbar-toggler-icon {
      background-image: url("/images/Group699.png");
      width: 42px;
      height: 42px;
      transition: background 0.3s;
    }
  }

  .navbar-toggler.collapsed {
    background-color: $finland;
    padding: 0;

    .navbar-toggler-icon {
      background-image: url("/images/Group700.png");
      width: 61px;
      height: 61px;
      transition: all 0.3s;
    }
  }

  .banner.scrolled {
    padding-bottom: 1rem;
  }

  .nav-primary.mobile {
    width: 100vh;
    height: auto;
    background: $finland;

    ul#menu-mobile-menu,
    ul#menu-mobile-menu-1 {
      float: none;
      width: 100%;
      margin: 2rem auto;

      li {
        float: none;
        width: 100%;

        a.nav-link {
          color: $purewhite;
          font-size: $h2-max-size;
          font-family: $header-font;
          font-weight: 700;
          text-transform: uppercase;
          margin-left: 0.75rem;
        }
      }

      .dropdown-toggle::after {
        display: none;
      }

      .dropdown-toggle::before {
        display: inline-block;
        margin-right: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        margin-left: -1.5rem;
      }

      .dropdown-toggle:hover::before {
        transform: rotate(0.75turn);
      }

      .dropdown-menu {
        background: none;
        position: initial;

        li a.nav-link {
          font-size: $nav-child-link-size;
          font-weight: 400;
        }

        li a.nav-link:hover {
          background: none;
        }
      }

      .dropdown-menu.open {
        display: block !important;
        margin: 0;
      }
    }
  }

  .banner a.members-area,
  .banner a.event {
    border: 2px solid $purewhite;
    float: left;
    margin: 0 0.5rem 2rem 1rem;
  }

  .menu-buy-tickets-container {
    float: right;
  }
}

@include media-breakpoint-only(md) {
  .nav-link,
  .banner .nav-primary a {
    padding: 1rem 0.3rem;
    font-size: $nav-font-size;
  }

  .banner .navbar {
    margin-left: -4rem;
  }

  .menu-buy-tickets-container {
    margin-top: 2rem;
  }
}

@include media-breakpoint-up(md) {
  .banner .navbar {
    min-width: 750px;
  }

  .banner {
    margin-bottom: 1rem;
  }

  .banner .nav-primary ul {
    li.dropdown:hover > .dropdown-menu {
      display: block !important;
      margin-top: 0;
    }

    ul {
      margin: 0;
    }

    ul li {
      width: 100%;
    }
  }

  .banner a.members-area,
  .banner a.event {
    float: right;
    font-size: $readmore-size;
  }

  .headerButtons {
    position: absolute;
    right: 15px;
    margin-top: -2.5rem;
  }
}

@media (hover: hover) and (pointer: fine) {
  .banner .nav-primary ul {
    li.dropdown:hover > .dropdown-menu {
      display: block !important;
      margin: 0;
    }
  }
}

@include media-breakpoint-only(lg) {
  .banner {
    margin-bottom: 1rem;
  }

  .banner .nav-primary ul {
    margin-left: 32px;
  }

  .banner a.brand {
    /* margin-top: 2.5rem; */
    margin-top: 1.5rem;
  }

  .nav-link,
  .banner .nav-primary a {
    padding: 0.5rem 0.3rem;
  }

  .banner .navbar {
    margin-left: 1rem;
  }
}

@include media-breakpoint-up(lg) {
  .banner {
    margin: 0 0 1rem;
  }

  .banner a.brand {
    background-image: url("/images/FDCA-logo-sininen-400x126.jpg");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
    width: 112px;
    height: 32px;

    /* margin-top: 2.5rem; */
    margin-top: 1.5rem;
  }

  .banner a.members-area.logged-in {
    float: left;
    margin-right: 0.4rem;
    margin-bottom: 0.4rem;
  }

  .banner a.members-area.logged-in:first-of-type {
    margin-left: -1.4rem;
  }
}

@include media-breakpoint-between(lg, xl) {
  .login-buttons {
    max-width: 200px;
    margin-left: -5px;
  }

  .banner a.members-area.logged-in:first-of-type {
    margin-top: -2rem;
  }
}

// @include media-breakpoint-up(xxl) {
//   .banner {
//     // margin: 1.75rem 0 5rem;
//     margin: 0 0 1rem;
//   }
// }
.home .menu-buy-tickets-container {
  display: none;
}

.page-template-template-event-news-ticket-menu .nav-primary {
  display: none;
}
