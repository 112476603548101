// :root {
//   font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
// }
// font-size : clamp(2rem, 10vw, 5rem);

body {
  font-family: $font-stack;
  background-color: $background-color;
  overflow-x: hidden;
  color: $text-color;
}

h1 {
  //font-size: $h1-max-size;
  font-size: clamp(2.5rem, 5vw, 4.5rem);
  font-family: $h1-header-font;
  font-weight: $h1-weight;
  color: $h1-header-color;
  text-transform: uppercase;
}

h2 {
  //font-size: $h2-max-size;
  font-size: clamp(1.5rem, 4vw, 1.75rem);
  font-family: $header-font;
  font-weight: $h2-weight;
  color: $h1-header-color;
}

h3 {
  font-size: $h3-max-size;
  font-size: clamp(1.125rem, 3vw, 1.5rem);
  font-family: $header-font;
  font-weight: $h3-weight;
  color: $h3-header-color;
}

h4 {
  font-size: $h4-size;
  font-family: $header-font;
  font-weight: $h4-weight;
  color: $h4-header-color;
}

h5 {
  font-size: $h5-size;
  font-family: $header-font;
  font-weight: $h5-weight;
  color: $h4-header-color;
  text-transform: uppercase;
}

h1.big,
h3.big,
h5.big,
.super-big {
  font-family: $h1-header-font;
  font-weight: $h1-weight;
  color: $h1-header-color;
  text-transform: uppercase;
  //font-size: $jumbo;
  font-size: clamp(1.5rem, 4vw, 4.5rem);
  line-height: 1;
}

h3.medium {
  font-family: $h1-header-font;
  font-weight: $h1-weight;
  color: $h1-header-color;
  text-transform: uppercase;
  font-size: clamp(1.3rem, 4vw, 3rem);
  line-height: 1;
}

/* .super-big {
  font-size: clamp(3.5rem, 11vw, 12.5rem);
} */

p {
  font: $p-weight $font-size $text-font;
  color: $text-color;
  line-height: 1.5rem;
}

a {
  color: $finland;
  text-decoration: none;
}

p a {
  text-decoration: underline;
}

strong {
  font-weight: 700;
}

.nav-links {
  padding: 2rem 0 3rem;
}

a strong,
strong a,
a.readmore,
a.members-area,
a.event,
ul#menu-buy-tickets li a.nav-link,
ul#menu-buy-tickets-1 li a.nav-link {
  display: inline-flex;
  border: 2px solid $h1-header-color;
  border-radius: 4px;
  padding: 0.8rem 1rem;
  color: $purewhite;
  background-color: $h1-header-color;
  margin: 1rem 0 2rem;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
  font-size: $h5-size;
  font-family: $header-font;
}

a.event {
  background-color: $purewhite;
  color: $finland;
}

a.readmore.grey {
  border: none;
  background-color: $snow;
  color: $finland;
  font-size: $readmore-size;
  padding: 1rem 3rem;
}

a:hover,
a:active {
  text-decoration: none;
  color: $blueberry;
}

a:hover strong,
strong a:hover,
a.readmore:hover,
a.readmore:active,
a:active strong,
strong a:active,
a.members-area:active,
a.members-area:hover,
a.event:active,
a.event:hover,
ul#menu-buy-tickets li a.nav-link:hover,
ul#menu-buy-tickets li a.nav-link:active,
ul#menu-buy-tickets-1 li a.nav-link:hover,
ul#menu-buy-tickets-1 li a.nav-link:active {
  color: $purewhite;
  background-color: $blueberry;
  text-decoration: none;
  border: 2px solid $blueberry;
}

a.readmore.grey:hover,
a.readmore.grey:active {
  border: none;
  background-color: $blueberry;
  color: $snow;
}

.readmore button,
.readmore button a,
.vc_btn3-container.readmore a {
  background-image: none !important;
  display: inline-flex !important;
  border: 2px solid $h1-header-color !important;
  border-radius: 4px !important;
  padding: 0.8rem 1rem !important;
  color: $purewhite !important;
  background-color: $h1-header-color !important;
  margin: 1rem 0 2rem !important;
  position: relative !important;
  overflow: hidden !important;
  text-decoration: none !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  font-size: $h5-size !important;
  font-family: $header-font !important;
}

.readmore button:hover,
.readmore button:active,
.vc_btn3-container.readmore a:hover,
.vc_btn3-container.readmore a:active {
  color: $purewhite !important;
  background-color: $blueberry !important;
  text-decoration: none !important;
  border: 2px solid $blueberry !important;
}

.align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.align-left {
  float: left;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.align-right {
  float: right;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.wrap {
  min-height: 65vh;
}

.breadcrumb {
  font-size: 0.76rem;
}

.hidden {
  visibility: hidden;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.herobanner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  max-width: 100%;
  width: auto;
  height: auto;

  .big {
    min-height: 49.5%; /* 1920 x 950 px */
  }

  .medium {
    min-height: 38.3%; /* 1920 x 735 px */
  }
}

.alert-warning {
  color: $purewhite;
  background-color: $finland;
  border-color: $finland;
}

.maxWidthXl {
  padding-right: 30px;
  padding-left: 30px;
}

// .hero .maxWidthXl {
//   padding-left: 30px;
//   padding-right: 30px;
// }

@include media-breakpoint-up(md) {
  h1 {
    margin-bottom: 2.5rem;
  }

  h2 {
    margin-bottom: 1.5rem;
  }

  h3,
  h4,
  h5 {
    margin-bottom: 1rem;
  }
}

/*
@include media-breakpoint-up(md){
  $font-size: 14px;
  $line-height: 1.5rem;
}

@include media-breakpoint-up(lg){
  $font-size: 16px;
  $line-height: 1.5rem;
}
 */

@include media-breakpoint-up(xxl) {
  .maxWidthXl {
    max-width: 1620px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@mixin backdrop($property) {
  backdrop-filter: blur($property);
  -webkit-backdrop-filter: blur($property);
}

@mixin transition($property) {
  -webkit-transition: $property;
  -moz-transition: $property;
  -o-transition: $property;
  transition: $property;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -moz-transform: $property;
  transform: $property;
}

@mixin transform-origin($property) {
  -webkit-transform-origin: $property;
  -moz-transform-origin: $property;
  -ms-transform-origin: $property;
  -o-transform-origin: $property;
  transform-origin: $property;
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

/* https://codepen.io/MadeByMike/pen/vNrvdZ */
@mixin interpolate($properties, $min-screen, $max-screen, $min-value, $max-value) {
  & {
    @each $property in $properties {
      #{$property}: $min-value;
    }

    @media screen and (min-width: $min-screen) {
      @each $property in $properties {
        #{$property}: calc-interpolation($min-screen, $min-value, $max-screen, $max-value);
      }
    }

    @media screen and (min-width: $max-screen) {
      @each $property in $properties {
        #{$property}: $max-value;
      }
    }
  }
}

@function calc-interpolation($min-screen, $min-value, $max-screen, $max-value) {
  $a: ($max-value - $min-value) / ($max-screen - $min-screen);
  $b: $min-value - $a * $min-screen;

  $sign: "+";

  @if ($b < 0) {
    $sign: "-";
    $b: abs($b);
  }

  @return calc(#{$a*100}vw #{$sign} #{$b});
}
